<template>
  <div>
    <Table
      label="Highlight"
      :actions="actions"
      :items="$store.getters['highlight/tableData']"
      :detail="$store.getters['highlight/detailData']"
      :fields="fields"
      :keyword="$store.state.highlight.tableKeyword"
      :loading="$store.state.highlight.loadingTable"
      :filter="$store.state.highlight.tableFilter"
      :sort="$store.state.highlight.tableSort"
      :sort-options="sortOptions"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @search="search"
      @loadDetail="loadDetail"
      @resetDetail="resetDetail"
    />

    <!-- Delete Service confirmation modal -->
    <b-modal
      v-model="deleteModalVisible"
      title="Delete Highlight"
      ok-title="Yes, delete"
      cancel-variant="white"
      @ok="deleteHightlight"
    >
      Are you sure want to delete this highlight?. This action cannot be undone.
    </b-modal>
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      deleteModalVisible: false,
      selectedData: '',

      // Sort
      sortOptions: [
        {
          key: 'createdAt', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'createdAt', direction: 'asc', label: 'Oldest' },
      ],

      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'imgUrl',
          label: 'Image',
          type: 'image',
        },
        {
          key: 'featuredImage',
          label: 'Featured Image',
          type: 'image',
        },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'description',
          label: 'Description',
          visibility: ['view'],
        },
        {
          key: 'url',
          label: 'Url',
          visibility: ['view'],
        },
        {
          key: 'sort',
          label: 'Sort',
          prefix: '#',
        },
        {
          key: 'rule.countries',
          label: 'Countries',
          type: 'countries',
        },
        {
          key: 'createdAt',
          label: 'Created at',
          type: 'datetime',
        },
        {
          key: 'updatedAt',
          label: 'Updated at',
          type: 'datetime',
          visibility: ['view'],
        },
      ],

      actions: [
        'view',
        'create',
        'update',
        {
          type: 'other',
          children: [
            {
              label: 'Delete',
              event: this.confirmDelete,
              visibility: {
                callback: data => !data.deletedAt && this.canAccess('manage', 'Highlight'),
              },
            },
          ],
        },
      ],
    }
  },
  methods: {
    loadData() {
      return this.$store.dispatch('highlight/getTableData')
    },
    filterData(val) {
      this.$store.commit('highlight/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('highlight/SET_TABLE_SORT', val)
    },
    search(val) {
      this.$store.commit('highlight/SET_TABLE_KEYWORD', val)
    },
    loadDetail(id) {
      return this.$store.dispatch('highlight/getDetail', id)
    },
    resetDetail() {
      this.$store.commit('highlight/SET_DETAIL_DATA', undefined)
    },
    deleteHightlight() {
      this.$store.dispatch('highlight/delete', this.selectedData.id)
        .then(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'The highlight has been deleted!',
              variant: 'success',
            },
          })

          this.deleteModalVisible = false
          this.loadData()
        })
    },
    confirmDelete(data) {
      this.selectedData = data.item
      this.deleteModalVisible = true
    },
  },
}
</script>
